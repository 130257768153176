import { Heading, SimpleGrid, Stack } from '@chakra-ui/react'
import DashboardItem from '../components/DashboardItem'
import useIsSuperAdmin from '../hooks/useIsSuperAdmin'
import useStagesCount from '../hooks/useStagesCount'

export default () => {
  const isSuperAdmin = useIsSuperAdmin()
  const stagesCount = useStagesCount()

  return (
    <Stack spacing={4}>
      <Heading>Admin</Heading>
      <SimpleGrid minChildWidth="300px" spacing={2}>
        <DashboardItem to="/admin/config" title="Configuration" />
        <DashboardItem to="/help" title="Manage FAQ" />
        {isSuperAdmin && (
          <>
            <DashboardItem to="/admin/users" title="Users" />
            <DashboardItem to="/admin/completion" title="Completion" />
          </>
        )}
        <DashboardItem to="/admin/brands" title="Brands" />
        <DashboardItem to="/admin/supplements" title="Supplements" />
        <DashboardItem to="/admin/diets" title="Diets" />
        <DashboardItem to="/admin/protocols" title="Protocols" />
        <DashboardItem to="/admin/mental-strategies" title="Mind Strategies" />
        <DashboardItem
          to="/admin/physical-strategies"
          title="Physical Strategies"
        />
        <DashboardItem
          to="/admin/non-sup-medications"
          title="Non-Supplement Medications"
        />
        <DashboardItem to="/admin/doctors" title="Doctors" />
        <DashboardItem
          to="/admin/stages"
          title={`${stagesCount} Steps to Healing`}
        />
        <DashboardItem to="/admin/labs" title="Lab Tests" />
        <DashboardItem to="/admin/studies" title="Studies" />
      </SimpleGrid>
    </Stack>
  )
}
