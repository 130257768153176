import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast,
} from '@chakra-ui/react'
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import useHandleError from '../hooks/useHandleError'
import useStore from '../store/useStore'

export default () => {
  const meta = useStore((s) => s.meta)
  const [dayOfMonthLimit, setDayOfMonthLimit] = useState(
    (meta?.dayOfMonthLimit || 15).toString()
  )
  const [numBootcamps, setNumBootcamps] = useState(
    (meta?.numBootcamps || 3).toString()
  )
  const [bootcampNames, setBootcampNames] = useState<string[]>(
    Array(parseInt(numBootcamps)).fill('')
  )
  const [loading, setLoading] = useState(false)
  const handleError = useHandleError()
  const toast = useToast()

  useEffect(() => {
    if (meta) {
      setDayOfMonthLimit(meta.dayOfMonthLimit.toString())
      setNumBootcamps(meta.numBootcamps.toString())
      setBootcampNames(meta.bootcampNames || [])
    }
  }, [meta])

  useEffect(() => {
    const fetchBootcampNames = async () => {
      try {
        const docRef = doc(getFirestore(), 'config/meta')
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          const data = docSnap.data()
          setBootcampNames(data.bootcampNames || [])
        }
      } catch (error) {
        handleError(error)
      }
    }

    fetchBootcampNames()
  }, [])

  const handleBootcampNameChange = (index: number, value: string) => {
    const newBootcampNames = [...bootcampNames]
    newBootcampNames[index] = value
    setBootcampNames(newBootcampNames)
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
          await updateDoc(doc(getFirestore(), 'config/meta'), {
            dayOfMonthLimit: parseInt(dayOfMonthLimit),
            numBootcamps: parseInt(numBootcamps),
            bootcampNames: bootcampNames,
          })
          toast({
            title: 'Success',
            description: 'New configuration saved successfully.',
            status: 'success',
          })
        } catch (error) {
          handleError(error)
        } finally {
          setLoading(false)
        }
      }}
    >
      <Stack spacing={6}>
        <Heading>Configuration</Heading>
        <FormControl isRequired maxW="xl">
          <FormLabel>Day of month limit</FormLabel>
          <Input
            placeholder="Last day of month to input last month data"
            type="number"
            value={dayOfMonthLimit}
            onChange={(e) => setDayOfMonthLimit(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired maxW="xl">
          <FormLabel>Number of Bootcamps</FormLabel>
          <Input
            placeholder="Enter the number of bootcamps"
            type="number"
            value={numBootcamps}
            onChange={(e) => setNumBootcamps(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired maxW="xl">
          <FormLabel>Bootcamp Names</FormLabel>
          {Array.from({ length: parseInt(numBootcamps) }).map((_, index) => (
            <Input
              key={index}
              placeholder={`Enter the name of bootcamp #${index + 1}`}
              type="text"
              value={bootcampNames[index] || ''}
              onChange={(e) => handleBootcampNameChange(index, e.target.value)}
              mb={2}
            />
          ))}
        </FormControl>
        <Box>
          <Button isDisabled={loading} isLoading={loading} type="submit">
            Save
          </Button>
        </Box>
      </Stack>
    </form>
  )
}
