import {
  Alert,
  AlertDescription,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  SimpleGrid,
  Stack,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { map } from 'lodash'
import moment from 'moment'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import MonthlyReportNavButtons from '../components/MonthlyReportNavButtons'
import VitalStatsInputLabel from '../components/VitalStatsInputLabel'
import useCurrentReport from '../hooks/useCurrentReport'
import useNavigateNext from '../hooks/useNavigateNext'
import useReportingForMonth from '../hooks/useReportingForMonth'
import useReports from '../hooks/useReports'
import useUnits from '../hooks/useUnits'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'
import { VitalStats } from '../types/MonthlyReport'
import { formatMonth } from '../utils/formatMonth'

export default () => {
  const stats: Partial<VitalStats> = useCurrentReport()?.vitalStats || {}
  const { month, year } = useReportingForMonth()
  const units = useUnits()
  const updateReport = useUpdateReportDoc()
  const navNext = useNavigateNext()

  const update = (partial: Partial<VitalStats>) => {
    updateReport({
      vitalStats: {
        ...stats,
        ...partial
      }
    })
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        navNext()
      }}
    >
      <Stack spacing={4}>
        <MonthlyReportHeader title='Vital Statistics' />
        <Tabs>
          <TabList>
            <Tab>
              Input for {moment.monthsShort()[month - 1]} {year}
            </Tab>
            <Tab>Vital Stats History</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Stack>
                <Alert
                  status='info'
                  size='sm'
                  rounded='md'
                  backgroundColor='purple.100'
                  variant='subtle'
                >
                  <AlertDescription>
                    Weight is the only required field. All other fields on
                    this page are optional.
                  </AlertDescription>
                </Alert>
                <SimpleGrid columns={[1, 2]} spacing={4}>
                  <FormControl isRequired>
                    <VitalStatsInputLabel
                      url={
                        'https://www.amazon.com/Hutchison-Medical-Patient-Lift-Scale/dp/B07CM7MVRC/ref=dp_fod_1?pd_rd_i=B07CM7MVRC&psc=1'
                      }
                      title='Weight'
                      description='If you cannot use a standard scale, you can use a Hoyer Lift scale.'
                    />
                    <InputGroup>
                      <Input
                        type='number'
                        step='any'
                        value={stats.weight || ''}
                        required
                        onChange={e => {
                          update({
                            weight: parseFloat(e.target.value),
                            units
                          })
                        }}
                      />
                      <InputRightAddon>
                        {units === 'metric' ? 'kg' : 'lbs'}
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      url={
                        'https://www.amazon.com/Innovo-Fingertip-Oximeter-Plethysmograph-Perfusion/dp/B077ZJ1ZKZ/ref=sr_1_17?crid=1P5F2YFM91549&keywords=nuvomed+pulse+oximeter+fingertip&qid=1652734395&sprefix=nuvo%2Caps%2C99&sr=8-17'
                      }
                      title='O2 Saturation'
                      description='Measure your Oxygen Saturation with an Oximeter.'
                    />
                    <InputGroup>
                      <Input
                        type='number'
                        step='any'
                        min={0}
                        max={100}
                        value={stats.o2saturation || ''}
                        onChange={e => {
                          update({
                            o2saturation: parseFloat(e.target.value)
                          })
                        }}
                      />
                      <InputRightAddon children='%' />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      url={
                        'https://www.amazon.com/Innovo-Fingertip-Oximeter-Plethysmograph-Perfusion/dp/B077ZJ1ZKZ/ref=sr_1_17?crid=1P5F2YFM91549&keywords=nuvomed+pulse+oximeter+fingertip&qid=1652734395&sprefix=nuvo%2Caps%2C99&sr=8-17'
                      }
                      title='Heart Rate'
                      description='Measure your Heart Rate with an Oximeter.'
                    />
                    <InputGroup>
                      <Input
                        type='number'
                        step='any'
                        value={stats.heartRate || ''}
                        onChange={e => {
                          update({ heartRate: parseFloat(e.target.value) })
                        }}
                      />
                      <InputRightAddon children='/minute' />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      url={
                        'https://www.amazon.com/Calibra%C2%AE-Aneroid-Professional-Sphygmomanometer-Free-Parts/dp/B00LV74908/ref=sxin_15_pa_sp_search_thematic_sspa?content-id=amzn1.sym.fe3abdfa-d248-4e07-8b0d-b8a0a47d4a6c%3Aamzn1.sym.fe3abdfa-d248-4e07-8b0d-b8a0a47d4a6c&crid=1XN9R04MAZTPX&cv_ct_cx=blood%2Bpressure%2Bcuff%2Bmanual&keywords=blood%2Bpressure%2Bcuff%2Bmanual&pd_rd_i=B00LV74908&pd_rd_r=7dd92fd1-c424-442e-bbdd-3874f2fe9f26&pd_rd_w=87tfP&pd_rd_wg=5NAdg&pf_rd_p=fe3abdfa-d248-4e07-8b0d-b8a0a47d4a6c&pf_rd_r=R0HX5E56VVAEPEHM2Z73&qid=1675113184&sprefix=%2Caps%2C111&sr=1-1-a73d1c8c-2fd2-4f19-aa41-2df022bcb241-spons&smid=A1AUY345WIU0B5&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFFQkQxVDVMWFZNTzcmZW5jcnlwdGVkSWQ9QTA5MTgzNjIzRk9MQjBWWTBWVE5SJmVuY3J5cHRlZEFkSWQ9QTA2MDE4MDMyRTRTVEZNRklFR0cxJndpZGdldE5hbWU9c3Bfc2VhcmNoX3RoZW1hdGljJmFjdGlvbj1jbGlja1JlZGlyZWN0JmRvTm90TG9nQ2xpY2s9dHJ1ZQ&th=1'
                      }
                      title='Blood Pressure'
                    />
                    <SimpleGrid columns={2} spacing={2}>
                      <InputGroup>
                        <InputLeftAddon fontSize='sm'>
                          Systolic
                        </InputLeftAddon>
                        <Input
                          type='number'
                          step='any'
                          value={stats.systolic || ''}
                          onChange={e => {
                            update({
                              systolic: parseFloat(e.target.value)
                            })
                          }}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputLeftAddon fontSize='sm'>
                          Diastolic
                        </InputLeftAddon>
                        <Input
                          type='number'
                          step='any'
                          value={stats.diastolic || ''}
                          onChange={e => {
                            update({
                              diastolic: parseFloat(e.target.value)
                            })
                          }}
                        />
                      </InputGroup>
                    </SimpleGrid>
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      url={
                        'https://www.amazon.com/Med-Lab-Diagnostics-Urinalysis-Alkalinity/dp/B06XG6MYXD?keywords=ph+strips&qid=1675111722&sr=8-9&linkCode=sl1&tag=ouofthbo024-20&linkId=f4bd23a0356912310fd046f79c85ce06&language=en_US&ref_=as_li_ss_tl'
                      }
                      title='pH Test (Urine - AM Before Eating)'
                    />
                    <Input
                      type='number'
                      step='any'
                      value={stats.phTestUrine || ''}
                      onChange={e => {
                        update({ phTestUrine: parseFloat(e.target.value) })
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      url={
                        'https://www.amazon.com/Med-Lab-Diagnostics-Urinalysis-Alkalinity/dp/B06XG6MYXD?keywords=ph+strips&qid=1675111722&sr=8-9&linkCode=sl1&tag=ouofthbo024-20&linkId=f4bd23a0356912310fd046f79c85ce06&language=en_US&ref_=as_li_ss_tl'
                      }
                      title='pH Test (Saliva - AM Before Eating)'
                    />
                    <Input
                      type='number'
                      step='any'
                      value={stats.phTestSaliva || ''}
                      onChange={e => {
                        update({
                          phTestSaliva: parseFloat(e.target.value)
                        })
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      title='Basal Body Temperature'
                      description='Underarm tempurature in the morning before you get up with a basal thermometer.'
                      url={
                        'https://www.amazon.com/iProven-Basal-Thermometer-Jumbo-Backlight/dp/B0BNL95N2V/ref=sr_1_4_sspa?crid=VNOB56S8GBTN&keywords=basal+thermometer+for+ovulation&qid=1675113549&sprefix=basal+thermometer%22%2Caps%2C136&sr=8-4-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExVFZOQjUxRFRPWU5YJmVuY3J5cHRlZElkPUEwNDc1NTkwMlhQOTk2RkJVRDBYOCZlbmNyeXB0ZWRBZElkPUEwNjgzODI1MTROUkdXVTlRUzdSOCZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU='
                      }
                    />
                    <Input
                      type='number'
                      step='any'
                      value={stats.basalBodyTemperature || ''}
                      onChange={e => {
                        update({
                          basalBodyTemperature: parseFloat(e.target.value)
                        })
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      title='Sed. Rate (Urine)'
                      description='Urine test strip sedimentation rate'
                    />
                    <Input
                      type='number'
                      step='any'
                      value={stats.sedRateUrine || ''}
                      onChange={e => {
                        update({
                          sedRateUrine: parseFloat(e.target.value)
                        })
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Glucose Level (Fasting)</FormLabel>
                    <Input
                      type='number'
                      step='any'
                      value={stats.glucoseFasted || ''}
                      onChange={e => {
                        update({
                          glucoseFasted: parseFloat(e.target.value)
                        })
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      Glucose Level (30 Min. After Meal)
                    </FormLabel>
                    <Input
                      type='number'
                      step='any'
                      value={stats.glucoseAfterMeal || ''}
                      onChange={e => {
                        update({
                          glucoseAfterMeal: parseFloat(e.target.value)
                        })
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      url='https://www.amazon.com/dp/B09C1W1V2F/ref=cm_sw_r_sms_api_i_CD7EWTG0WSYNWVCA44KV'
                      title='Grip Strength'
                    />
                    <SimpleGrid columns={2} spacing={2}>
                      <InputGroup>
                        <InputLeftAddon>Left</InputLeftAddon>
                        <Input
                          type='number'
                          step='any'
                          value={stats.gripLeft || ''}
                          onChange={e => {
                            update({
                              gripLeft: parseFloat(e.target.value)
                            })
                          }}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputLeftAddon>Right</InputLeftAddon>
                        <Input
                          type='number'
                          step='any'
                          value={stats.gripRight || ''}
                          onChange={e => {
                            update({
                              gripRight: parseFloat(e.target.value)
                            })
                          }}
                        />
                      </InputGroup>
                    </SimpleGrid>
                  </FormControl>

                  <FormControl>
                    <VitalStatsInputLabel
                      url='https://www.amazon.com/8889992-Mouthpiece-Spirometer-Disposable-Diagnostics/dp/B00D7C4O5K/ref=sr_1_11?crid=DQ9E27Y3M3MC&keywords=peak+flow+meter+manual&qid=1652750420&sprefix=peak+flow+meter+%2Caps%2C101&sr=8-11#immersive-view_1652750684411'
                      title='Peak Flow Meter Value (0-850)'
                    />
                    <Input
                      type='number'
                      step='any'
                      min={0}
                      max={850}
                      value={stats.peakFlow || ''}
                      onChange={e => {
                        update({ peakFlow: parseFloat(e.target.value) })
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      title='FVC (Only If Measured by Respiratory Therapist)'
                      description='Forced Vital Capacity - Leave blank if you have not seen a respiratory therapist this month.'
                    />
                    <InputGroup>
                      <Input
                        type='number'
                        step='any'
                        value={stats.fvc || ''}
                        onChange={e => {
                          update({ fvc: parseFloat(e.target.value) })
                        }}
                      />
                      <InputRightAddon children='%' />
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <VitalStatsInputLabel
                      title='SVC (Only If Measured by Respiratory Therapist)'
                      description='Slow Vital Capacity - Leave blank if you have not seen a respiratory therapist this month.'
                    />
                    {/* <FormLabel>
                      Slow Vital Capacity (SVC - Measured by Respiratory
                      Therapist)
                    </FormLabel> */}
                    <InputGroup>
                      <Input
                        type='number'
                        step='any'
                        value={stats.svc || ''}
                        onChange={e => {
                          update({ svc: parseFloat(e.target.value) })
                        }}
                      />
                      <InputRightAddon children='%' />
                    </InputGroup>
                  </FormControl>
                </SimpleGrid>
              </Stack>
            </TabPanel>
            <TabPanel>
              <VitalStatsTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <MonthlyReportNavButtons />
      </Stack>
    </form>
  )
}

export const VitalStatsTable = () => {
  const reports = useReports()

  return (
    <Stack spacing={4}>
      <TableContainer>
        <Table size='sm' variant='unstyled'>
          <Thead>
            <Tr>
              <Th>Month</Th>
              <Th>Weight</Th>
              <Th>
                O2
                <br />
                Sat%
              </Th>
              <Th>
                Heart
                <br />
                Rate
              </Th>
              <Th>
                Blood
                <br />
                Pressure
              </Th>
              <Th>
                PH Test
                <br />
                Urine
              </Th>
              <Th>
                PH Test
                <br />
                Saliva
              </Th>
              <Th>
                Basal Body
                <br />
                Temp.
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {map(reports, (report, key) => {
              const stats = report?.vitalStats
              if (!stats) return null

              return (
                <Tr key={key}>
                  <Td>{formatMonth(key)}</Td>
                  <Td>
                    {stats.weight}
                    {stats.units === 'imperial' ? 'lbs' : 'kg'}
                  </Td>
                  <Td>{stats.o2saturation}</Td>
                  <Td>{stats.heartRate}</Td>
                  <Td>
                    {stats.systolic}/{stats.diastolic}
                  </Td>
                  <Td>{stats.phTestUrine}</Td>
                  <Td>{stats.phTestSaliva}</Td>
                  <Td>{stats.basalBodyTemperature}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <TableContainer>
        <Table size='sm' variant='unstyled'>
          <Thead>
            <Tr>
              <Th>Month</Th>
              <Th>
                Sed. Rate
                <br />
                (Urine)
              </Th>
              <Th>
                Glucose
                <br />
                Fasting
              </Th>
              <Th>
                Glucose 30 Min
                <br />
                After Meal
              </Th>
              <Th>
                Grip Str
                <br />
                Left
              </Th>
              <Th>
                Grip Str
                <br />
                Right
              </Th>
              <Th>
                Peak Flow
                <br />
                Meter
              </Th>

              <Th>
                Forced Vital
                <br />
                Capacity
              </Th>
              <Th>
                Slow Vital
                <br />
                Capacity
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {map(reports, (report, key) => {
              const stats = report?.vitalStats
              if (!stats) return null

              return (
                <Tr key={key}>
                  <Td>{formatMonth(key)}</Td>
                  <Td>{stats.sedRateUrine}</Td>
                  <Td>{stats.glucoseFasted}</Td>
                  <Td>{stats.glucoseAfterMeal}</Td>
                  <Td>{stats.gripLeft}</Td>
                  <Td>{stats.gripRight}</Td>
                  <Td>{stats.peakFlow}</Td>
                  <Td>{stats.fvc}</Td>
                  <Td>{stats.svc}</Td>
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}
