import { Stack, Text } from '@chakra-ui/react'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import useFirestoreUser from '../hooks/useFirestoreUser'
import useReportingForMonth from '../hooks/useReportingForMonth'
import MonthlyReportNavButtons from './MonthlyReportNavButtons'

export default ({ title }: { title: string }) => {
  const location = useLocation()
  const parts = location.pathname.split('/')
  const { month, year } = useReportingForMonth()
  const step = parseInt(parts[3])
  const user = useFirestoreUser()

  return (
    <Stack spacing={5}>
      <Stack spacing={0}>
        <Text
          as='span'
          fontSize='md'
          fontWeight='normal'
          color='purple.600'
        >
          Reporting for{' '}
          <Text as='span' fontWeight='bold' fontSize='md'>
            {moment.months()[month - 1]} {year}
          </Text>
        </Text>
        <Text fontSize='lg' fontWeight='bold'>
          Step #{step}:{' '}
          <Text fontSize='lg' as='span' fontWeight='normal'>
            {title}
          </Text>
        </Text>
        <Text fontSize='sm' as='span' fontWeight='normal'>
          Patient ID: #{user?.patientId}
        </Text>
      </Stack>
      <MonthlyReportNavButtons />
    </Stack>
  )
}
