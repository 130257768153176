import {
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { QueryDocumentSnapshot } from 'firebase/firestore'
import { Link } from 'react-router-dom'
import FirestoreUser, {
  shortDiagnosisTitles,
  shortRoleTitles
} from '../types/FirestoreUser'

export default ({
  docs,
  lastNameFirst
}: {
  docs: QueryDocumentSnapshot<FirestoreUser>[]
  lastNameFirst?: boolean
}) => {
  return (
    <TableContainer>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>ID</Th>
            <Th>Diagnosis</Th>
            <Th>Role</Th>
            <Th>Phone</Th>
            <Th>Email</Th>
            <Th>Country</Th>
          </Tr>
        </Thead>
        <Tbody>
          {docs.map(doc => {
            const { profile, patientId, email, roles } = doc.data()
            if (!profile) return null

            return (
              <Tr key={doc.id}>
                <Td>
                  <Link to={`/admin/users/${doc.id}`}>
                    <Button variant='link'>
                      {lastNameFirst
                        ? `${profile.lastName}, ${profile.firstName}`
                        : `${profile.firstName} ${profile.lastName}`}
                    </Button>
                  </Link>
                </Td>
                <Td>{patientId}</Td>
                <Td>{shortDiagnosisTitles[profile.diagnosis]}</Td>
                <Td>{roles.map(r => shortRoleTitles[r]).join('/')}</Td>
                <Td>{profile.phoneNumber}</Td>
                <Td>{email}</Td>
                <Td>{profile.country}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
