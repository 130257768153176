import useConfigList from '../hooks/useConfigList'
import Regimens from './Regimens'

export default () => {
  const items = useConfigList('mentalStrategies')

  return (
    <Regimens
      title='Mind Strategies'
      recordLabel='Mind Strategy'
      durationEnabled
      frequencyEnabled
      lists={[
        {
          fieldId: 'mentalStrategyId',
          label: 'Strategy',
          items,
          id: 'mentalStrategies'
        }
      ]}
    />
  )
}
