import { range } from 'lodash'
import {
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { PartialWithFieldValue, doc, setDoc } from 'firebase/firestore'

import { collections } from '../../AAinitFirebase'
import useHandleError from '../../hooks/useHandleError'
import useStore from '../../store/useStore'
import FirestoreUser from '../../types/FirestoreUser'

import TableCell from './TableCell'

const Bootcamps = ({
  editable,
  user,
}: {
  editable: boolean
  user: FirestoreUser
}) => {
  const uid = useStore((s) => s.user?.uid)
  const numBootcamps = useStore((s) => s.meta?.numBootcamps || 0)
  const bootcampNames = useStore((s) => s.meta?.bootcampNames || [])
  const handleError = useHandleError()

  const updateUserBootcamps = async (
    userBootCampData: PartialWithFieldValue<FirestoreUser>
  ) => {
    try {
      await setDoc(doc(collections.users, uid), userBootCampData, {
        merge: true,
      })
    } catch (e) {
      handleError(e)
    }
  }

  const handleModalSubmit = (
    bootcampCompletionDate: { month: number; year: number },
    bootcampIndex: number
  ) => {
    const bootcampData = user.bootCamps?.[bootcampIndex]

    updateUserBootcamps({
      bootCamps: {
        [bootcampIndex]: {
          ...bootcampData,
          completedAt: bootcampCompletionDate,
        },
      },
    })
  }

  const handleUncheck = (bootcampIndex: number, bootcampData: any) => {
    updateUserBootcamps({
      bootCamps: {
        [bootcampIndex]: {
          ...bootcampData,
          completedAt: null,
        },
      },
    })
  }

  return (
    <Stack spacing={4}>
      <Text>Which Bootcamps have you completed?</Text>
      <TableContainer>
        <Table size="sm" variant="simple">
          <Thead>
            <Tr>
              <Th width="80%"></Th>
              <Th>Completed</Th>
            </Tr>
          </Thead>
          <Tbody>
            {range(0, numBootcamps).map((bootcampIndex) => {
              if (!uid) return null

              const bootcampData = user.bootCamps?.[bootcampIndex]
              const bootcampName = bootcampNames?.[bootcampIndex]

              return (
                <Tr key={bootcampIndex}>
                  <Td>{bootcampName}</Td>
                  <TableCell
                    value={bootcampData?.completedAt || null}
                    onUncheck={() => handleUncheck(bootcampIndex, bootcampData)}
                    editable={editable}
                    onCheck={(date) => {
                      handleModalSubmit(date, bootcampIndex)
                    }}
                    modalTitle="Bootcamp Complete"
                    modalSubtitle={`When did you complete ${bootcampNames[bootcampIndex]}?`}
                  />
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  )
}

export default Bootcamps
