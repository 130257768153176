import { useToast } from '@chakra-ui/react'

export default () => {
  const toast = useToast()

  return (e: any) => {
    console.error(e)

    let description = 'Unknown error.'

    if (typeof e === 'string') {
      description = e
    } else if (typeof e.message === 'string') {
      description = e.message
    }
    toast({
      title: 'Error',
      description,
      status: 'error',
      isClosable: true
    })
  }
}
