import {
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@chakra-ui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useUserDoc from '../hooks/useUserDoc'
import useStore from '../store/useStore'
import EditPersonalProfile from './EditPersonalProfile'

const hashes = ['#personal', '#dental', '#exposures']

const EditProfile = ({ uid }: { uid: string }) => {
  const user = useUserDoc(uid)
  const navigate = useNavigate()
  const hash = useLocation().hash || hashes[0]

  return (
    <Stack spacing={2}>
      <Heading>Profile</Heading>
      <Text fontSize='sm'>Patient ID: #{user?.patientId}</Text>
      {user?.email && <Text fontSize='sm'>{user.email}</Text>}
      <Tabs
        variant='enclosed-colored'
        colorScheme='purple'
        onChange={index => {
          navigate(hashes[index])
        }}
        index={hashes.indexOf(hash)}
      >
        <TabList>
          <Tab fontWeight='bold'>Personal</Tab>
          <Tab fontWeight='bold'>Dental</Tab>
          <Tab fontWeight='bold'>Exposures</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {user && (
              <EditPersonalProfile uid={uid} firestoreUser={user} />
            )}
          </TabPanel>
          <TabPanel>
            <Text>Coming soon!</Text>
          </TabPanel>
          <TabPanel>
            <Text>Coming soon!</Text>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

export default () => {
  const myUid = useStore(s => s.user?.uid)

  return myUid ? <EditProfile uid={myUid} /> : null
}

export const AdminEditProfile = () => {
  const { id } = useParams<{ id: string }>()

  return id ? <EditProfile uid={id} /> : null
}
