import {
  ChakraProvider,
  Container,
  extendTheme,
  withDefaultColorScheme,
  withDefaultVariant,
} from '@chakra-ui/react'
import '@fontsource/karla/400-italic.css'
import '@fontsource/karla/400.css'
import '@fontsource/karla/700.css'
import { MultiSelectTheme } from 'chakra-multiselect'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import {
  CollectionReference,
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { map } from 'lodash'
import { useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Navigate, Route, Routes } from 'react-router-dom'
import Toolbar from './components/Toolbar'
import useIsAdmin from './hooks/useIsAdmin'
import useShowQuery from './hooks/useShowQuery'
import ALSFRS from './pages/ALSFRS'
import ALSSQ from './pages/ALSSQ'
import AddBloodWork from './pages/AddBloodWork'
import Admin from './pages/Admin'
import AdminBloodWork from './pages/AdminBloodWork'
import AdminCompletion from './pages/AdminCompletion'
import AdminConfig from './pages/AdminConfig'
import AdminDoctors from './pages/AdminDoctors'
import AdminListPage from './pages/AdminListPage'
import AdminPrint from './pages/AdminPrint'
import AdminStages from './pages/AdminStages'
import AdminUserPage from './pages/AdminUserPage'
import BloodWork from './pages/BloodWork'
import ClinicalObservations from './pages/ClinicalObservations'
import CreateBloodWorkLab from './pages/CreateBloodWorkLab'
import Diets from './pages/Diets'
import EditProfile, { AdminEditProfile } from './pages/EditProfile'
import Help from './pages/Help'
import Home from './pages/Home'
import Landing from './pages/Landing'
import MentalStrategies from './pages/MentalStrategies'
import NonSupMedicationsForm from './pages/NonSupMedicationsForm'
import NotFound from './pages/NotFound'
import PANAS from './pages/PANAS'
import PhysicalStrategies from './pages/PhysicalStrategies'
import Print from './pages/Print'
import ProgressionQuery from './pages/ProgressionQuery'
import ReportComplete from './pages/ReportComplete'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import Stages from './pages/Stages/index'
import Stool from './pages/Stool'
import SupplementsForm from './pages/SupplementsForm'
import Support from './pages/Support'
import Users from './pages/Users'
import VitalStats from './pages/VitalStats'
import useStore, { LocalConfig, MonthlyReport } from './store/useStore'
import ConfigList from './types/ConfigList'
import Doctor from './types/Doctor'
import { Regimen } from './types/Regimen'
import userDoc from './utils/userDoc'

const client = new QueryClient()

export default () => {
  const user = useStore((state) => state.user)
  const isAdmin = useIsAdmin()
  const showQuery = useShowQuery()

  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      useStore.setState({ user })
    })
  }, [])

  useEffect(() => {
    onSnapshot(
      query(
        collection(getFirestore(), 'doctors') as CollectionReference<Doctor>
      ),
      (doctors) => {
        useStore.setState({ doctors })
      }
    )
  }, [])

  useEffect(() => {
    if (!user) {
      return useStore.setState({
        firestoreUser: undefined,
        regimens: [],
        reports: {},
      })
    }

    const unsubRegimens = onSnapshot(
      query(
        collection(getFirestore(), 'regimens') as CollectionReference<
          Omit<Regimen, 'id'>
        >,
        where('uid', '==', user.uid)
      ),
      (snap) => {
        useStore.setState({
          regimens: snap.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            }
          }),
        })
      }
    )

    const unsubReports = onSnapshot(
      query(
        collection(
          getFirestore(),
          `users/${user.uid}/reports`
        ) as CollectionReference<MonthlyReport>
      ),
      (snap) => {
        const reports: Record<string, MonthlyReport> = {}
        snap.forEach((doc) => {
          reports[doc.id] = doc.data()
        })
        useStore.setState({ reports })
      }
    )

    const unsubUserDoc = onSnapshot(userDoc(user.uid), (snap) => {
      useStore.setState({ firestoreUser: snap.data() })
    })

    return () => {
      unsubUserDoc()
      unsubRegimens()
      unsubReports()
    }
  }, [user])

  if (user === undefined) return null

  return (
    <QueryClientProvider client={client}>
      <ChakraProvider
        theme={extendTheme(
          {
            fonts: {
              heading: `'Karla', sans-serif`,
              body: `'Karla', sans-serif`,
            },
            components: {
              MultiSelect: MultiSelectTheme,
            },
          },
          withDefaultColorScheme({
            colorScheme: 'purple',
          }),
          withDefaultVariant({
            variant: 'left-accent',
            components: ['Alert'],
          })
        )}
      >
        {user && <Toolbar />}
        <Container maxW="container.lg" minH="100vh" py={4}>
          <Routes>
            <Route path="/app" element={<Navigate to="/" replace />} />
            {user ? (
              <>
                {isAdmin && (
                  <>
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/admin/doctors" element={<AdminDoctors />} />
                    <Route path="/admin/config" element={<AdminConfig />} />
                    <Route
                      path="/admin/brands"
                      element={<AdminListPage title="Brands" docId="brands" />}
                    />
                    <Route
                      path="/admin/supplements"
                      element={
                        <AdminListPage
                          title="Supplements"
                          docId="supplements"
                        />
                      }
                    />
                    <Route
                      path="/admin/diets"
                      element={<AdminListPage title="Diets" docId="diets" />}
                    />
                    <Route
                      path="/admin/protocols"
                      element={
                        <AdminListPage title="Protocols" docId="protocols" />
                      }
                    />
                    <Route
                      path="/admin/mental-strategies"
                      element={
                        <AdminListPage
                          title="Mind Strategies"
                          docId="mentalStrategies"
                        />
                      }
                    />
                    <Route
                      path="/admin/physical-strategies"
                      element={
                        <AdminListPage
                          title="Physical Strategies"
                          docId="physicalStrategies"
                        />
                      }
                    />
                    <Route
                      path="/admin/non-sup-medications"
                      element={
                        <AdminListPage
                          title="Non-Supplement Medication"
                          docId="nonSupplementMedications"
                        />
                      }
                    />
                    <Route
                      path="admin/studies"
                      element={
                        <AdminListPage title="Studies" docId="studies" />
                      }
                    />
                    <Route
                      path="/admin/users/:id"
                      element={<AdminUserPage />}
                    />
                    <Route
                      path="/admin/users/:id/edit-profile"
                      element={<AdminEditProfile />}
                    />
                    <Route path="/admin/users" element={<Users />} />
                    <Route
                      path="/admin/completion"
                      element={<AdminCompletion />}
                    />
                    <Route path="/admin/stages" element={<AdminStages />} />
                    <Route path="/admin/labs" element={<AdminBloodWork />} />
                    <Route
                      path="/admin/labs/create"
                      element={<CreateBloodWorkLab />}
                    />
                    <Route
                      path="/admin/labs/:editingId/edit"
                      element={<CreateBloodWorkLab />}
                    />
                    <Route
                      path="/admin/users/:uid/print"
                      element={<AdminPrint />}
                    />
                  </>
                )}
                <Route path="/profile" element={<EditProfile />} />
                <Route path="/help" element={<Help />} />
                <Route path="/report/:month/1" element={<ALSFRS />} />
                <Route path="/report/:month/2" element={<ALSSQ />} />
                <Route path="/report/:month/3" element={<PANAS />} />
                <Route path="/report/:month/4" element={<Stool />} />
                <Route path="/report/:month/5" element={<VitalStats />} />
                <Route
                  path="/report/:month/6"
                  element={<ClinicalObservations />}
                />
                <Route path="/report/:month/7" element={<Diets />} />
                <Route path="/report/:month/8" element={<SupplementsForm />} />
                <Route
                  path="/report/:month/9"
                  element={<NonSupMedicationsForm />}
                />
                <Route
                  path="/report/:month/10"
                  element={<PhysicalStrategies />}
                />
                <Route
                  path="/report/:month/11"
                  element={<MentalStrategies />}
                />
                <Route path="/report/:month/12" element={<Stages />} />
                <Route path="/report/:month/13" element={<Support />} />
                <Route path="/report/:month/14" element={<ReportComplete />} />
                <Route path="/report/:month/100" element={<ReportComplete />} />
                <Route path="/labs" element={<BloodWork />} />
                <Route path="/labs/create" element={<AddBloodWork />} />
                <Route path="/print" element={<Print />} />
                {showQuery && (
                  <>
                    <Route path="/query" element={<ProgressionQuery />} />
                    <Route path="/users/:id" element={<AdminUserPage />} />
                    <Route path="/users/:uid/print" element={<AdminPrint />} />
                  </>
                )}
                <Route path="/" element={<Home />} />
              </>
            ) : (
              <>
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/" element={<Landing />} />
              </>
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </ChakraProvider>
    </QueryClientProvider>
  )
}

// listen to config list documents
onSnapshot(collection(getFirestore(), 'config'), (snap) => {
  const config: LocalConfig = {}

  snap.forEach((doc) => {
    if (doc.id === 'stages') {
      useStore.setState({ stages: doc.data().value })
    } else if (doc.id === 'meta') {
      useStore.setState({ meta: doc.data() as any })
    } else {
      const data = doc.data() as ConfigList
      config[doc.id] = {}

      // add items only if they are not archived
      map(data.value, (value, key) => {
        if (!data.archivedIds?.includes(key)) {
          config[doc.id][key] = value
        }
      })
    }
  })

  useStore.setState({ config })
})
