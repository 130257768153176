import { AddIcon, CopyIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast
} from '@chakra-ui/react'
import { addDoc, doc, getDoc, updateDoc } from 'firebase/firestore'
import { chain } from 'lodash'
import { Fragment, useEffect, useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import labsCollection, { useLabs } from '../data/labsCollection'
import useHandleError from '../hooks/useHandleError'
import {
  BloodWorkUnit,
  Lab,
  Panel,
  PanelItem,
  bloodWorkUnits
} from '../types/bloodWork'
import randomId from '../utils/randomId'
import NotFound from './NotFound'

type LabInput = {
  title: string
  panels: {
    id: string
    title: string
    items: PanelItemInput[]
    archived?: boolean
  }[]
  archived?: boolean
}

type PanelItemInput = {
  id: string
  title: string
  unit: BloodWorkUnit | ''
  referenceRange: {
    bottom: string
    top: string
  }
  optimalRange: {
    bottom: string
    top: string
  }
  archived?: boolean
}

export default () => {
  const { editingId } = useParams<{ editingId: string }>()
  const [lab, setLab] = useState<LabInput>({ title: '', panels: [] })
  const [copyLabId, setCopyLabId] = useState('')
  const [saving, setSaving] = useState(false)
  const [loadingCopy, setLoadingCopy] = useState(!!editingId)
  const labs = useLabs({ allowArchived: true })
  const handleError = useHandleError()
  const toast = useToast()
  const [notFound, setNotFound] = useState(false)
  const navigate = useNavigate()

  const setLabFromCopy = (copyLab: Lab) => {
    setLab({
      title: copyLab.title,
      archived: copyLab.archived,
      panels: copyLab.panels.map(panel => {
        return {
          id: panel.id,
          title: panel.title,
          archived: panel.archived,
          items: panel.items.map(item => {
            return {
              id: item.id,
              unit: item.unit,
              title: item.title,
              referenceRange: {
                top: item.referenceRange.top.toString() || '',
                bottom: item.referenceRange.bottom.toString() || ''
              },
              optimalRange: {
                top: item.optimalRange?.top.toString() || '',
                bottom: item.optimalRange?.bottom.toString() || ''
              },
              archived: item.archived
            }
          })
        }
      })
    })
  }

  useEffect(() => {
    if (!editingId) return

    getDoc(doc(labsCollection, editingId))
      .then(snap => {
        const lab = snap.data()
        if (lab) {
          setLabFromCopy(lab)
        } else {
          setNotFound(true)
        }
      })
      .finally(() => {
        setLoadingCopy(false)
      })
  }, [editingId])

  if (notFound) {
    return <NotFound />
  }

  return (
    <Stack spacing={5}>
      <Heading>{editingId ? 'Edit' : 'Create'} Lab</Heading>
      <Divider />
      {!editingId && (
        <>
          <Stack spacing={2}>
            <FormControl maxW='md'>
              <FormLabel>Copy Existing Lab</FormLabel>
              <Select
                maxW='md'
                placeholder='Choose Lab to Copy'
                value={copyLabId}
                onChange={e => {
                  setCopyLabId(e.target.value)
                }}
              >
                {labs.map(doc => {
                  return (
                    <option value={doc.id} key={doc.id}>
                      {doc.data().title}
                    </option>
                  )
                })}
              </Select>
            </FormControl>
            <Box>
              <Button
                colorScheme='gray'
                leftIcon={<CopyIcon />}
                isDisabled={!copyLabId}
                onClick={() => {
                  const copyLab = labs
                    .find(doc => doc.id === copyLabId)
                    ?.data()
                  if (
                    copyLab &&
                    window.confirm(
                      `Warning: This will overwrite this entire form and replace it with the entirety of lab "${copyLab.title}". Proceed?`
                    )
                  ) {
                    setCopyLabId('')
                    setLabFromCopy(copyLab)
                  }
                }}
              >
                Copy
              </Button>
            </Box>
          </Stack>
          <Divider />
        </>
      )}

      <Stack spacing={3}>
        <FormControl maxW='md'>
          <FormLabel>Lab Title</FormLabel>
          <Input
            placeholder='e.g. Quest, LabCorp, etc...'
            value={lab.title}
            onChange={e => {
              setLab({
                ...lab,
                title: e.target.value
              })
            }}
          />
        </FormControl>
        <HStack spacing={2}>
          <Button
            size='sm'
            variant='outline'
            leftIcon={<AddIcon />}
            onClick={() => {
              setLab({
                ...lab,
                panels: [
                  ...lab.panels,
                  {
                    id: randomId(),
                    title: '',
                    items: []
                  }
                ]
              })
            }}
          >
            Add Panel
          </Button>
          <Button
            size='sm'
            variant='outline'
            colorScheme={lab.archived ? 'gray' : 'red'}
            leftIcon={lab.archived ? <FaEye /> : <FaEyeSlash />}
            onClick={() => {
              setLab({
                ...lab,
                archived: !lab.archived
              })
            }}
          >
            {lab.archived ? 'Unarchive Lab' : 'Archive Lab'}
          </Button>
        </HStack>
      </Stack>
      {lab.panels.map(panel => {
        return (
          <Fragment key={panel.id}>
            <Divider />
            <Stack spacing={3}>
              <FormControl maxW='md'>
                <FormLabel>Panel Title</FormLabel>
                <Input
                  placeholder='e.g. Comprehensive Metabolic Panel, Complete Blood Count, etc...'
                  value={panel.title}
                  onChange={e => {
                    setLab({
                      ...lab,
                      panels: lab.panels.map(p => {
                        if (p.id !== panel.id) return p
                        return {
                          ...p,
                          title: e.target.value
                        }
                      })
                    })
                  }}
                />
              </FormControl>
              <HStack spacing={2}>
                <Button
                  size='sm'
                  variant='outline'
                  leftIcon={<AddIcon />}
                  onClick={() => {
                    setLab({
                      ...lab,
                      panels: lab.panels.map(p => {
                        if (p.id !== panel.id) return p
                        return {
                          ...p,
                          items: [
                            ...p.items,
                            {
                              id: randomId(),
                              referenceRange: {
                                bottom: '',
                                top: ''
                              },
                              optimalRange: {
                                bottom: '',
                                top: ''
                              },
                              title: '',
                              unit: '',
                              archived: false
                            }
                          ]
                        }
                      })
                    })
                  }}
                >
                  Add Panel Item
                </Button>
                <Button
                  size='sm'
                  colorScheme={panel.archived ? 'gray' : 'red'}
                  variant='outline'
                  leftIcon={panel.archived ? <FaEye /> : <FaEyeSlash />}
                  onClick={() => {
                    setLab({
                      ...lab,
                      panels: lab.panels.map(p => {
                        if (panel.id === p.id) {
                          return {
                            ...p,
                            archived: !panel.archived
                          }
                        } else return p
                      })
                    })
                  }}
                >
                  {panel.archived ? 'Unarchive Panel' : 'Archive Panel'}
                </Button>
              </HStack>
              <TableContainer>
                <Table size='sm' variant='simple'>
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Unit</Th>
                      <Th>Ref. Rng.</Th>
                      <Th>Opt. Rng.</Th>
                      <Th />
                    </Tr>
                  </Thead>
                  <Tbody>
                    {panel.items.map((item, index) => {
                      const update = (
                        partial: Partial<PanelItemInput>
                      ) => {
                        setLab({
                          ...lab,
                          panels: lab.panels.map(p => {
                            if (p.id !== panel.id) return p
                            const copy = [...p.items]
                            copy[index] = {
                              ...p.items[index],
                              ...partial
                            }
                            return {
                              ...p,
                              items: copy
                            }
                          })
                        })
                      }
                      return (
                        <Tr key={item.id}>
                          <Td>
                            <Input
                              size='sm'
                              placeholder='e.g. Sodium, Potassium, etc...'
                              value={item.title}
                              onChange={e => {
                                update({ title: e.target.value })
                              }}
                            />
                          </Td>
                          <Td>
                            <Select
                              size='sm'
                              value={item.unit}
                              placeholder='Unit'
                              onChange={e => {
                                const { value } = e.target
                                update({
                                  unit: value as BloodWorkUnit
                                })
                              }}
                            >
                              {chain(bloodWorkUnits)
                                .map((text, unit) => {
                                  return {
                                    text,
                                    unit
                                  }
                                })
                                .orderBy('text', 'asc')
                                .map(({ text, unit }) => {
                                  return (
                                    <option value={unit} key={unit}>
                                      {text}
                                    </option>
                                  )
                                })
                                .value()}
                            </Select>
                          </Td>
                          <Td>
                            <Input
                              size='sm'
                              value={item.referenceRange.bottom}
                              type='number'
                              maxW='60px'
                              onChange={e => {
                                update({
                                  referenceRange: {
                                    ...item.referenceRange,
                                    bottom: e.target.value
                                  }
                                })
                              }}
                            />
                            {' - '}
                            <Input
                              size='sm'
                              type='number'
                              maxW='60px'
                              value={item.referenceRange.top}
                              onChange={e => {
                                update({
                                  referenceRange: {
                                    ...item.referenceRange,
                                    top: e.target.value
                                  }
                                })
                              }}
                            />
                          </Td>
                          <Td>
                            <Input
                              size='sm'
                              value={item.optimalRange.bottom}
                              type='number'
                              maxW='60px'
                              onChange={e => {
                                update({
                                  optimalRange: {
                                    ...item.optimalRange,
                                    bottom: e.target.value
                                  }
                                })
                              }}
                            />
                            {' - '}
                            <Input
                              size='sm'
                              value={item.optimalRange.top}
                              type='number'
                              maxW='60px'
                              onChange={e => {
                                update({
                                  optimalRange: {
                                    ...item.optimalRange,
                                    top: e.target.value
                                  }
                                })
                              }}
                            />
                          </Td>
                          <Td>
                            <Box>
                              <Button
                                variant='link'
                                colorScheme={
                                  item.archived ? undefined : 'red'
                                }
                                onClick={() => {
                                  update({ archived: !item.archived })
                                }}
                                size='sm'
                              >
                                {item.archived ? 'Unarchive' : 'Archive'}
                              </Button>
                            </Box>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Stack>
          </Fragment>
        )
      })}
      <Box>
        <Button
          size='lg'
          isDisabled={saving}
          isLoading={saving}
          onClick={async () => {
            try {
            } catch (e) {}
            setSaving(true)
            try {
              const newLab: Lab = {
                archived: lab.archived || false,
                title: lab.title,
                panels: lab.panels.map(panelInput => {
                  const panel: Panel = {
                    id: panelInput.id,
                    title: panelInput.title,
                    items: panelInput.items.map(itemInput => {
                      const item: PanelItem = {
                        id: itemInput.id,
                        title: itemInput.title,
                        unit: itemInput.unit as BloodWorkUnit,
                        referenceRange: {
                          bottom: parseInt(
                            itemInput.referenceRange.bottom
                          ),
                          top: parseInt(itemInput.referenceRange.top)
                        }
                      }
                      if (
                        itemInput.optimalRange.bottom &&
                        itemInput.optimalRange.top
                      ) {
                        item.optimalRange = {
                          bottom: parseInt(itemInput.optimalRange.bottom),
                          top: parseInt(itemInput.optimalRange.top)
                        }
                      }
                      return item
                    })
                  }

                  return panel
                })
              }

              if (editingId) {
                await updateDoc(doc(labsCollection, editingId), newLab)
              } else {
                await addDoc(labsCollection, newLab)
                setLab({
                  panels: [],
                  title: ''
                })
              }
              navigate(-1)
              toast({ status: 'success', description: 'Lab saved!' })
            } catch (e) {
              handleError(e)
            } finally {
              setSaving(false)
            }
          }}
        >
          Save
        </Button>
      </Box>
    </Stack>
  )
}
