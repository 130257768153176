import {
  Alert,
  AlertDescription,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Radio,
  Stack,
  StackDivider,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { chain, keys, map } from 'lodash'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import MonthlyReportNavButtons from '../components/MonthlyReportNavButtons'
import useCurrentReport, {
  useLastMonthReport
} from '../hooks/useCurrentReport'
import useNavigateNext from '../hooks/useNavigateNext'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'

type Answers = { [row: string]: number }
const ALLOW_COPY_FROM_LAST_MONTH = false

export default () => {
  const updateReport = useUpdateReportDoc()
  const currentReport = useCurrentReport()
  const report = {
    main: {
      answers: {},
      positive: 0,
      negative: 0,
      net: 0,
      complete: false
    },
    supplemental: {
      answers: {},
      positive: 0,
      negative: 0,
      net: 0,
      complete: false
    },
    modifiedPanas: {
      answers: {},
      positive: 0,
      negative: 0,
      net: 0,
      complete: false
    },
    basic: basicDefault,
    ...currentReport?.panas
  }
  const navNext = useNavigateNext()
  // const answers = report.main.answers
  // const answers2 = report.supplemental.answers
  const modifiedPanasAnswers = report.modifiedPanas.answers

  const lastMonthReport = useLastMonthReport()

  console.log({ lastMonthReport })

  const update = async (partial: Partial<PANASReport>) => {
    updateReport({
      panas: {
        ...report,
        ...partial
      }
    })
  }

  const setAnswersCallback = (key: keyof PANASReport) => {
    return (answers: Answers) => {
      let positive = 0
      let negative = 0

      map(answers, (value, row) => {
        if (row[row.length - 1] === '+') {
          positive += value
        } else {
          negative += value
        }
      })

      update({
        [key]: {
          answers,
          positive,
          negative,
          net: positive - negative,
          complete:
            keys(answers).length ===
            (key === 'main' ? part1 : part2).length
        }
      })
    }
  }

  const lastMonthPanas = lastMonthReport?.panas

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        navNext()
      }}
    >
      <Stack spacing={6}>
        <MonthlyReportHeader title='Tell us about your mindset.' />
        {ALLOW_COPY_FROM_LAST_MONTH &&
          lastMonthPanas?.main.complete &&
          lastMonthPanas.supplemental.complete &&
          !lastMonthPanas.copiedFromLastMonth && (
            <Alert status='info'>
              <Stack>
                <AlertDescription>
                  Since you fully completed PANAS in your previous report,
                  you have the option to skip it for this month. This will
                  copy all of your answers from the previous month.
                </AlertDescription>
                <Box>
                  <Button
                    variant='link'
                    onClick={() => {
                      if (
                        window.confirm(
                          'Copy all your answers from the previous month?'
                        )
                      ) {
                        update({
                          ...lastMonthPanas,
                          copiedFromLastMonth: true
                        })
                        navNext()
                      }
                    }}
                  >
                    Copy from Previous Month
                  </Button>
                </Box>
              </Stack>
            </Alert>
          )}
        <Stack>
          <Heading fontSize='xl'>Part A</Heading>
          <Stack spacing={1}>
            <BasicPercentInput
              question='What percentage of the day are you thinking positive thoughts? (0-100%)'
              value={report.basic.positiveThoughts}
              onChange={value =>
                update({
                  basic: { ...report.basic, positiveThoughts: value }
                })
              }
            />
            <BasicPercentInput
              question='What percentage of the day are you in a state of fear, worry, or anxiety? (0-100%)'
              value={report.basic.fearOrWorry || ''}
              onChange={value =>
                update({
                  basic: { ...report.basic, fearOrWorry: value }
                })
              }
            />
            <BasicPercentInput
              question='What percentage have you forgiven other people and situations?  (0-100%)'
              value={report.basic.forgiveOthers}
              onChange={value =>
                update({
                  basic: { ...report.basic, forgiveOthers: value }
                })
              }
            />
            <BasicPercentInput
              question='What percentage have you forgiven yourself for anything, current or past? (0-100%)'
              value={report.basic.forgiveSelf}
              onChange={value =>
                update({
                  basic: { ...report.basic, forgiveSelf: value }
                })
              }
            />
            <BasicPercentInput
              question='What percentage of past trauma have you released? (0-100%)'
              value={report.basic.releaseTrauma}
              onChange={value =>
                update({
                  basic: { ...report.basic, releaseTrauma: value }
                })
              }
            />
          </Stack>
        </Stack>
        <Stack>
          <Heading fontSize='xl'>Part B</Heading>
          <TableContainer>
            <Table size='sm' variant='unstyled'>
              <Thead>
                <Tr>
                  <Th></Th>
                  {map(panasBasicOptions, (value, key) => {
                    return (
                      <Th textAlign='center' key={key}>
                        {value}
                      </Th>
                    )
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {basicSelectQuestions.map((q, index) => {
                  return (
                    <BasicTableRow
                      key={q.name}
                      index={index}
                      name={q.name}
                      text={q.question}
                      value={report.basic[q.name]}
                      onChange={value => {
                        update({
                          basic: {
                            ...basicDefault,
                            ...report.basic,
                            [q.name]: value
                          }
                        })
                      }}
                    />
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
        <Stack>
          <Heading fontSize='xl'>Part C</Heading>
          <HStack spacing={3} divider={<StackDivider />}>
            <Box>
              <Stat>
                <StatLabel>Positive Affect Score</StatLabel>
                <StatNumber>{report.modifiedPanas.positive}</StatNumber>
              </Stat>
            </Box>
            <Box>
              <Stat>
                <StatLabel>Negative Affect Score</StatLabel>
                <StatNumber>{report.modifiedPanas.negative}</StatNumber>
              </Stat>
            </Box>
          </HStack>
          <Section
            items={modifiedPanas}
            answers={modifiedPanasAnswers}
            setAnswers={setAnswersCallback('modifiedPanas')}
            title='Modified Panas'
          />
        </Stack>
        {/* <Section
          items={part1}
          answers={answers}
          setAnswers={setAnswersCallback('main')}
          title='PANAS-SF'
        />
        <Section
          items={part2}
          answers={answers2}
          setAnswers={setAnswersCallback('supplemental')}
          title='Supplemental'
        /> */}
        <MonthlyReportNavButtons />
      </Stack>
    </form>
  )
}

const Section = ({
  title,
  items,
  answers,
  setAnswers
}: {
  title: string
  items: string[]
  answers: Answers
  setAnswers: (answers: Answers) => void
}) => {
  return (
    <TableContainer>
      <Table size='sm' variant='unstyled'>
        <Thead>
          <Tr>
            <Th>{title}</Th>
            {options.map(option => {
              return (
                <Th textAlign='center' key={option}>
                  {option}
                </Th>
              )
            })}
          </Tr>
        </Thead>
        <Tbody>
          {items.map((row, index) => {
            return (
              <Tr
                key={row}
                backgroundColor={index % 2 === 0 ? 'purple.50' : 'white'}
              >
                <Td fontSize='lg'>
                  {index + 1}. {row.substring(0, row.length - 1)}
                </Td>
                {options.map((_, value) => {
                  return (
                    <Td
                      key={value}
                      cursor='pointer'
                      onClick={() => {
                        setAnswers({
                          ...answers,
                          [row]: value
                        })
                      }}
                      _hover={{
                        background: 'gray.100'
                      }}
                    >
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                      >
                        <Radio
                          key={row + value}
                          isChecked={answers[row] === value}
                        />
                      </Box>
                    </Td>
                  )
                })}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}

const part1 = [
  'Interested+',
  'Distressed-',
  'Excited+',
  'Upset-',
  'Strong+',
  'Guilty-',
  'Scared-',
  'Hostile-',
  'Enthusiastic+',
  'Proud+',
  'Irritable-',
  'Alert+',
  'Ashamed-',
  'Inspired+',
  'Nervous-',
  'Determined+',
  'Attentive+',
  'Jittery-',
  'Active+',
  'Afraid-'
]

const part2 = `Belief in a higher power+
Anxious-
Follow my intuition+
Mindful+
Poor Me-
Self-Aware+
Suppress Emotions-
Confident+
Depressed-
Judge self and others-
Frustrated-
Embrace social support+
Unworthy-
Have strong reasons for living+
Fear of rejection or shame-
Hopeful+
Powerless-
Take control of my health+
Grateful+
Fear of disability or death-`.split('\n')

const modifiedPanas = [
  'Hopeful+',
  'Angry-',
  'Guilty-',
  'Follow my intuition+',
  'Determined+',
  'Worried/Stressed-',
  'Depressed-',
  'Grateful+',
  'Unworthy-',
  'Have strong reasons for living+',
  'Powerless/Frustrated-',
  'Take control of my health+',
  'Ashamed/Embarassed-',
  'Self-aware of thoughts & feelings+',
  'Feel like a victim/Why me?-',
  'Embrace Social Support+',
  'Fear of Disability or Death-',
  'Spiritual or belief in higher power+',
  'Judgmental of Self & Others+',
  'Enjoy life+'
]

console.log('mp', modifiedPanas.length)

const options = [
  'None of the time',
  'Sometimes',
  'Moderate',
  'Most of the time',
  'Always'
]
export const panasBasicOptions = {
  yes: 'Yes',
  maybe: 'Maybe',
  no: 'No'
}

const basicDefault: BasicReport = {
  ableToGetWell: 'maybe',
  believeCanGetWell: 'maybe',
  deserveToGetWell: 'maybe',
  forgiveOthers: '',
  forgiveSelf: '',
  positiveThoughts: '',
  readyToGetWell: 'maybe',
  releaseTrauma: '',
  safeForOthersGetWell: 'maybe',
  safeToGetWell: 'maybe',
  wantToBeWell: 'maybe',
  willingToGetWell: 'maybe',
  fearOrWorry: ''
}

type BasicReport = {
  positiveThoughts: string
  forgiveOthers: string
  forgiveSelf: string
  releaseTrauma: string
  fearOrWorry: string
  wantToBeWell: keyof typeof panasBasicOptions
  believeCanGetWell: keyof typeof panasBasicOptions
  safeToGetWell: keyof typeof panasBasicOptions
  safeForOthersGetWell: keyof typeof panasBasicOptions
  readyToGetWell: keyof typeof panasBasicOptions
  willingToGetWell: keyof typeof panasBasicOptions
  ableToGetWell: keyof typeof panasBasicOptions
  deserveToGetWell: keyof typeof panasBasicOptions
}

export type PANASReport = {
  main: PANASSectionReport
  supplemental: PANASSectionReport
  basic?: BasicReport
  copiedFromLastMonth?: boolean
  modifiedPanas?: PANASSectionReport
}

const basicSelectQuestions: {
  name: keyof BasicReport
  question: string
}[] = [
  {
    name: 'wantToBeWell',
    question: 'I want to be well.'
  },
  {
    name: 'believeCanGetWell',
    question: 'I believe I can get well.'
  },
  {
    name: 'safeToGetWell',
    question: 'Is it safe for me to get well.'
  },
  {
    name: 'safeForOthersGetWell',
    question: 'It is safe for others that I get well.'
  },
  {
    name: 'readyToGetWell',
    question: 'I am ready to get well.'
  },
  {
    name: 'willingToGetWell',
    question: 'I am willing to get well.'
  },
  {
    name: 'ableToGetWell',
    question: 'I am able to get well.'
  },
  {
    name: 'deserveToGetWell',
    question: 'I deserve to get well.'
  }
]

const BasicPercentInput = ({
  question,
  onChange,
  value
}: {
  onChange: (value: string) => void
  value: string
  question: string
}) => {
  return (
    <FormControl isRequired display='flex'>
      <FormLabel flex={3}>{question}</FormLabel>
      <Input
        flex={1}
        value={value}
        onChange={e => onChange(e.target.value)}
        type='number'
        placeholder='0-100%'
        step='1.0'
      />
    </FormControl>
  )
}

const BasicTableRow = ({
  name,
  text,
  index,
  onChange,
  value: _value
}: {
  name: keyof BasicReport
  text: string
  index: number
  onChange: (value: string) => void
  value: string
}) => {
  return (
    <Tr backgroundColor={index % 2 === 0 ? 'purple.50' : 'white'}>
      <Td fontSize='lg'>
        {index + 1}. {text}
      </Td>
      {map(panasBasicOptions, value => {
        return (
          <Td
            key={value}
            cursor='pointer'
            onClick={() => {
              onChange(value)
            }}
            _hover={{
              background: 'gray.100'
            }}
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <Radio key={name + value} isChecked={_value === value} />
            </Box>
          </Td>
        )
      })}
    </Tr>
  )
}

export type PANASSectionReport = {
  answers: Answers
  positive: number
  negative: number
  net: number
  complete: boolean
}

export function isPANASReportComplete(report: PANASReport) {
  const basicComplete =
    report.basic &&
    chain(report.basic)
      .map((value, key) => {
        return {
          value,
          key
        }
      })
      .every(item => {
        return item.value.length > 0
      })
      .value()
  console.log({ basicComplete })
  return report.modifiedPanas?.complete && basicComplete
}
