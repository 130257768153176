import { AddIcon, QuestionIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { collection, doc, getFirestore } from 'firebase/firestore'
import { map } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCurrentOrPreviousReportsArray } from '../hooks/useReports'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'
import {
  EmotionalSupportPerson,
  EmotionalSupportType,
  SupportVenue,
  emotionalSupportLevels,
  emotionalSupportTypes,
  supportVenues
} from '../types/support'

export default () => {
  const addDialog = useDisclosure()
  const currentOrPastReports = useCurrentOrPreviousReportsArray()

  const people = useMemo(() => {
    return (
      currentOrPastReports[0]?.emotionalSupportPeople ||
      currentOrPastReports[1]?.emotionalSupportPeople ||
      []
    )
  }, [currentOrPastReports])

  const [editingId, setEditingId] = useState<string>()
  const [type, setType] = useState<EmotionalSupportType>()
  const [emotionalSupportLevel, setEmotionalSupportLevel] =
    useState<number>()
  const [physicalSupportLevel, setPhysicalSupportLevel] =
    useState<number>()
  const [nickname, setNickname] = useState('')
  const [venue, setVenue] = useState<SupportVenue>()
  const toast = useToast()
  const updateReport = useUpdateReportDoc()

  const setPeople = useCallback(
    async (emotionalSupportPeople: EmotionalSupportPerson[]) => {
      updateReport({ emotionalSupportPeople })
    },
    [updateReport]
  )

  useEffect(() => {
    if (venue === 'virtual') {
      setPhysicalSupportLevel(0)
    }
  }, [venue])

  const points = useMemo(() => {
    let emotional = 0,
      physical = 0
    people.forEach(p => {
      emotional += p.emotionalSupportLevel
      physical += p.physicalSupportLevel
    })
    return { emotional, physical }
  }, [people])

  const clearInputs = () => {
    setEditingId(undefined)
    setEmotionalSupportLevel(undefined)
    setPhysicalSupportLevel(undefined)
    setType(undefined)
    setNickname('')
    setVenue(undefined)
  }

  const physicalSupportCount = people.filter(
    p => p.physicalSupportLevel !== 0
  ).length

  return (
    <Stack>
      <HStack>
        <Text fontSize='large' fontWeight='bold'>
          Part 1:{' '}
        </Text>
        <Text fontSize='large'>Emotional & Physical Support</Text>
      </HStack>
      {people.length > 0 && (
        <SimpleGrid columns={[2, 4, 6]} spacing={3}>
          <Box>
            <Stat>
              <StatLabel>Emotional Support Pts</StatLabel>
              <StatNumber>{points ? points.emotional : '--'}</StatNumber>
            </Stat>
          </Box>
          <Box>
            <Stat>
              <StatLabel>Physical Support Pts</StatLabel>
              <StatNumber>{points ? points.physical : '--'}</StatNumber>
            </Stat>
          </Box>
          <Box>
            <Stat>
              <StatLabel># Emotional Support</StatLabel>
              <StatNumber>{people.length}</StatNumber>
            </Stat>
          </Box>
          <Box>
            <Stat>
              <StatLabel># Physical Support</StatLabel>
              <StatNumber>{physicalSupportCount}</StatNumber>
            </Stat>
          </Box>
          <Box>
            <Stat>
              <StatLabel>Emotional Support Avg</StatLabel>
              <StatNumber>
                {points?.emotional
                  ? (points.emotional / people.length).toFixed(2)
                  : '--'}
              </StatNumber>
            </Stat>
          </Box>
          <Box>
            <Stat>
              <StatLabel>Physical Support Avg</StatLabel>
              <StatNumber>
                {points?.physical
                  ? (points.physical / physicalSupportCount).toFixed(2)
                  : '--'}
              </StatNumber>
            </Stat>
          </Box>
        </SimpleGrid>
      )}
      <Box>
        <Button
          leftIcon={<AddIcon />}
          onClick={addDialog.onOpen}
          size='sm'
        >
          Add
        </Button>
      </Box>
      {people.length > 0 && (
        <FamilySupportTable
          people={people}
          onEdit={person => {
            setEditingId(person.id)
            setNickname(person.nickname)
            setEmotionalSupportLevel(person.emotionalSupportLevel)
            setPhysicalSupportLevel(person.physicalSupportLevel)
            setType(person.type)
            setVenue(person.venue)
            addDialog.onOpen()
          }}
          onRemove={person => {
            if (window.confirm('Remove this person from this report?')) {
              setPeople(people.filter(p => p.id !== person.id))
            }
          }}
        />
      )}
      <Modal isOpen={addDialog.isOpen} onClose={addDialog.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {editingId
              ? 'Edit Emotional/Physical Support'
              : 'Add Emotional/Physical Support'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Nickname</FormLabel>
                <Input
                  value={nickname}
                  onChange={e => setNickname(e.target.value)}
                  maxLength={50}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Relationship to You</FormLabel>
                <Select
                  placeholder='Choose Type'
                  value={type}
                  onChange={e =>
                    setType(e.target.value as EmotionalSupportType)
                  }
                >
                  {map(emotionalSupportTypes, (text, type) => {
                    return (
                      <option value={type} key={type}>
                        {text}
                      </option>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>In Person or Virtual?</FormLabel>
                <RadioGroup
                  onChange={s => setVenue(s as SupportVenue)}
                  value={venue}
                >
                  <Stack spacing={1}>
                    {map(supportVenues, (text, id) => {
                      return (
                        <Radio key={id} value={id}>
                          {text}
                          {id === 'virtual' && (
                            <>
                              {' '}
                              <Popover>
                                <PopoverTrigger>
                                  <QuestionIcon color='gray.400' />
                                </PopoverTrigger>
                                <PopoverContent>
                                  <PopoverArrow />
                                  <PopoverCloseButton />
                                  <PopoverHeader>Virtual</PopoverHeader>
                                  <PopoverBody>
                                    Webinar groups, Prayer group, Facebook
                                    groups, WhatsApp or Telegram Groups, In
                                    person or on-line support groups,
                                    Mastermind groups.
                                  </PopoverBody>
                                </PopoverContent>
                              </Popover>
                            </>
                          )}
                        </Radio>
                      )
                    })}
                  </Stack>
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Emotional Support Level</FormLabel>
                <RadioGroup
                  onChange={s => setEmotionalSupportLevel(parseInt(s))}
                  value={emotionalSupportLevel?.toString()}
                >
                  <LevelRadios noNotApplicable />
                </RadioGroup>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Physical Support Level</FormLabel>
                <RadioGroup
                  onChange={s => setPhysicalSupportLevel(parseInt(s))}
                  value={physicalSupportLevel?.toString()}
                >
                  <LevelRadios />
                </RadioGroup>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              size='sm'
              colorScheme='gray'
              onClick={() => {
                clearInputs()
                addDialog.onClose()
              }}
              mr={3}
            >
              Cancel
            </Button>
            <Button
              size='sm'
              onClick={() => {
                if (
                  !type ||
                  emotionalSupportLevel === undefined ||
                  physicalSupportLevel === undefined ||
                  !nickname ||
                  !venue
                ) {
                  return toast({
                    status: 'warning',
                    title: 'Field Missing',
                    description: 'Please fill out all fields.',
                    isClosable: true
                  })
                }
                const person = {
                  emotionalSupportLevel,
                  physicalSupportLevel,
                  type,
                  nickname: nickname.trim(),
                  venue
                }
                if (editingId) {
                  setPeople(
                    people.map(p => {
                      if (p.id === editingId) {
                        return {
                          ...p,
                          ...person
                        }
                      }
                      return p
                    })
                  )
                } else {
                  const sameNickname = people.find(
                    p =>
                      p.nickname.toLowerCase() ===
                      person.nickname.toLowerCase()
                  )
                  if (sameNickname) {
                    return toast({
                      status: 'warning',
                      title: 'Same nickname!',
                      description:
                        'You already have added a person with this nickname. Please choose another.',
                      isClosable: true
                    })
                  }
                  setPeople(
                    people.concat({
                      ...person,
                      id: doc(collection(getFirestore(), 'a')).id
                    })
                  )
                }

                clearInputs()
                addDialog.onClose()
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}

const LevelRadios = ({
  noNotApplicable
}: {
  noNotApplicable?: boolean
}) => {
  return (
    <Stack spacing={1}>
      {emotionalSupportLevels.map(level => {
        if (noNotApplicable && level.value === 0) return null

        return (
          <Radio value={level.value.toString()} key={level.value}>
            {level.title}
          </Radio>
        )
      })}
    </Stack>
  )
}

export const FamilySupportTable = ({
  people,
  onEdit,
  onRemove
}: {
  people: EmotionalSupportPerson[]
  onEdit?: (person: EmotionalSupportPerson) => void
  onRemove?: (person: EmotionalSupportPerson) => void
}) => {
  return (
    <TableContainer>
      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Nickname</Th>
            <Th>Type</Th>
            <Th>Emotional Support</Th>
            <Th>Physical Support</Th>
            <Th>Venue</Th>
            {onEdit && <Th />}
            {onRemove && <Th />}
          </Tr>
        </Thead>
        <Tbody>
          {people.map(person => {
            return (
              <Tr key={person.id}>
                <Td>{person.nickname}</Td>
                <Td>{emotionalSupportTypes[person.type]}</Td>
                <Td>
                  {
                    emotionalSupportLevels.find(
                      l => l.value === person.emotionalSupportLevel
                    )?.title
                  }
                </Td>
                <Td>
                  {
                    emotionalSupportLevels.find(
                      l => l.value === person.physicalSupportLevel
                    )?.title
                  }
                </Td>
                <Td>{supportVenues[person.venue]}</Td>
                {onEdit && (
                  <Td>
                    <Button
                      size='xs'
                      variant='link'
                      onClick={() => onEdit(person)}
                    >
                      Edit
                    </Button>
                  </Td>
                )}
                {onRemove && (
                  <Td>
                    <Button
                      size='xs'
                      variant='link'
                      colorScheme='red'
                      onClick={() => onRemove(person)}
                    >
                      Remove
                    </Button>
                  </Td>
                )}
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
