import useBrands from '../hooks/useBrands'
import useConfigList from '../hooks/useConfigList'
import Regimens from './Regimens'

export default () => {
  const items = useConfigList('nonSupplementMedications')
  const brands = useBrands()

  return (
    <Regimens
      title='Non-Supplement Medications'
      recordLabel='Medication'
      amountEnabled
      frequencyEnabled
      methodEnabled
      lists={[
        {
          fieldId: 'nonSupplementMedicationId',
          label: 'Medication',
          items,
          id: 'nonSupplementMedications'
        },
        {
          fieldId: 'brandId',
          label: 'Brand',
          items: brands,
          id: 'brands'
        }
      ]}
      alert='Please include ALL medications that you are taking, even if they are not for ALS.'
    />
  )
}
