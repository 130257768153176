import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Hide,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Stack,
  useBoolean,
  useColorModeValue,
  useDisclosure
} from '@chakra-ui/react'
import { getAuth, signOut } from 'firebase/auth'
import { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useFirestoreUser from '../hooks/useFirestoreUser'
import useIsAdmin from '../hooks/useIsAdmin'
import useShowQuery from '../hooks/useShowQuery'
import useStore from '../store/useStore'
import { contactEmail } from '../utils/contactEmail'

const NavLink = ({
  text,
  to,
  onClose
}: {
  text: string
  to: string
  onClose: () => void
}) => (
  <Box px={2} py={1}>
    <Link to={to} onClick={onClose}>
      <Button
        fontWeight='normal'
        color={useColorModeValue('black', 'white')}
        variant='link'
      >
        {text}
      </Button>
    </Link>
  </Box>
)

export default function Simple() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const isAdmin = useIsAdmin()
  const showQuery = useShowQuery()

  const Links = [
    { text: 'Dashboard', to: '/' },
    { text: 'Profile', to: '/profile' },
    { text: 'Print Records', to: '/print' },
    { text: 'Help', to: '/help' }
  ]

  if (isAdmin) {
    Links.push({ text: 'Admin', to: '/admin' })
  }
  if (showQuery) {
    Links.push({ text: 'Query', to: '/query' })
  }

  return (
    <Hide breakpoint='print'>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex
          h={16}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            colorScheme='gray'
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <Image
                src='/lotus.jpeg'
                rounded='full'
                height={8}
                width={8}
                objectFit='cover'
              />
            </Box>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}
            >
              {Links.map(link => (
                <NavLink key={link.to} {...link} onClose={onClose} />
              ))}
              <Contact />
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Menu>
              <Initials />
              <MenuList>
                <Link to='/profile'>
                  <MenuItem>Edit Profile</MenuItem>
                </Link>
                <Link to='/help'>
                  <MenuItem>Help</MenuItem>
                </Link>
                <MenuDivider />
                <SignOut />
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map(link => (
                <NavLink key={link.to} {...link} onClose={onClose} />
              ))}
              <Contact />
            </Stack>
          </Box>
        ) : null}
      </Box>
    </Hide>
  )
}

const Initials = () => {
  const user = useFirestoreUser()
  const email = useStore(state => state.user?.email)
  return (
    <MenuButton
      as={Button}
      rounded='full'
      variant='link'
      cursor='pointer'
      minW={0}
    >
      <Avatar
        size='sm'
        name={
          user?.profile
            ? `${user.profile.firstName} ${user.profile.lastName}}`
            : email
            ? email.charAt(0).toUpperCase()
            : '?'
        }
      />
    </MenuButton>
  )
}

const SignOut = () => {
  const [isOpen, setIsOpen] = useBoolean()
  const cancelRef = useRef<any>()
  const navigate = useNavigate()

  return (
    <>
      <MenuItem onClick={setIsOpen.on}>Sign Out</MenuItem>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={setIsOpen.off}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Sign Out
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to sign out?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme='gray'
                ref={cancelRef}
                onClick={setIsOpen.off}
              >
                Cancel
              </Button>
              <Button
                colorScheme='red'
                onClick={() => {
                  setIsOpen.off()
                  signOut(getAuth()).then(() => {
                    navigate('/')
                  })
                }}
                ml={3}
              >
                Sign Out
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const Contact = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const leastDestructiveRef = useRef<any>()
  return (
    <Box px={2} py={1}>
      <Button
        fontWeight='normal'
        color={useColorModeValue('black', 'white')}
        variant='link'
        onClick={onOpen}
      >
        Contact
      </Button>
      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={leastDestructiveRef}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Contact</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              To contact us, please email us at: {contactEmail}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={leastDestructiveRef} onClick={onClose}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  )
}
