import { Stack, Text, Textarea } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import MonthlyReportHeader from '../components/MonthlyReportHeader'
import MonthlyReportNavButtons from '../components/MonthlyReportNavButtons'
import useCurrentReport from '../hooks/useCurrentReport'
import useNavigateNext from '../hooks/useNavigateNext'
import useUpdateReportDoc from '../hooks/useUpdateReportDoc'

export default () => {
  const [clinicalObservations, setClinicalObservations] = useState(
    'Week 1:\n\n\nWeek 2:\n\n\nWeek 3:\n\n\nWeek 4:\n\n\nWeek 5:'
  )
  const updateReport = useUpdateReportDoc()
  const navNext = useNavigateNext()
  const report = useCurrentReport()

  useEffect(() => {
    if (typeof report?.clinicalObservations === 'string') {
      setClinicalObservations(report.clinicalObservations)
    }
  }, [report])

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        updateReport({ clinicalObservations })
        navNext()
      }}
    >
      <Stack spacing={4}>
        <MonthlyReportHeader title='Journal - Clinical Observations' />
        <Text>
          Please note any physical or mental changes during this month. We
          suggest one entry per week if you are starting a new protocol or
          supplements.
        </Text>
        <Textarea
          value={clinicalObservations}
          onChange={e => setClinicalObservations(e.target.value)}
          height='sm'
        />
        <MonthlyReportNavButtons />
      </Stack>
    </form>
  )
}
