import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import {
  collection,
  getCountFromServer,
  getFirestore,
  query,
  where
} from 'firebase/firestore'
import { keys } from 'lodash'
import { useEffect, useState } from 'react'
import useHandleError from '../hooks/useHandleError'
import { Diagnosis, diagnosisTitles } from '../types/FirestoreUser'

export default () => {
  const [counters, setCounters] = useState<
    {
      diagnosis: Diagnosis
      verified: number
      unverified: number
    }[]
  >()
  const handleError = useHandleError()

  useEffect(() => {
    const users = collection(getFirestore(), 'users')
    Promise.all(
      keys(diagnosisTitles).map(async d => {
        const diagnosis = d as Diagnosis

        const [verified, unverified] = await Promise.all([
          getCountFromServer(
            query(
              users,
              where('profile.diagnosis', '==', diagnosis),
              where('profile.diagnosisVerified', '==', true)
            )
          ),
          getCountFromServer(
            query(
              users,
              where('profile.diagnosis', '==', diagnosis),
              where('profile.diagnosisVerified', '==', false)
            )
          )
        ])

        return {
          diagnosis,
          verified: verified.data().count,
          unverified: unverified.data().count
        }
      })
    )
      .then(setCounters)
      // eslint-disable-next-line
      .catch(handleError)
  }, [handleError])

  if (!counters) return null

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Diagnosis</Th>
            <Th>Verified</Th>
            <Th>Unverified</Th>
            <Th>Total</Th>
          </Tr>
        </Thead>
        <Tbody>
          {counters.map(counter => {
            return (
              <Tr key={counter.diagnosis}>
                <Td>{diagnosisTitles[counter.diagnosis]}</Td>
                <Td>{counter.verified}</Td>
                <Td>{counter.unverified}</Td>
                <Td>{counter.verified + counter.unverified}</Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>
    </TableContainer>
  )
}
