import useConfigList from '../hooks/useConfigList'
import Regimens from './Regimens'

export default () => {
  const items = useConfigList('diets')

  return (
    <Regimens
      title='Diet'
      recordLabel='Diet'
      lists={[
        {
          fieldId: 'dietId',
          label: 'Diet',
          items,
          id: 'diets'
        }
      ]}
      allowUpdate={false}
      waterQuestionsEnabled
    />
  )
}
