import {
  Alert,
  AlertDescription,
  Button,
  Checkbox,
  Container,
  Flex,
  Input,
  Link,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import { getStorage, ref, uploadString } from 'firebase/storage'
import { useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import ReactSignatureCanvas from 'react-signature-canvas'
import AuthHeader from '../components/AuthHeader'
import useHandleError from '../hooks/useHandleError'
import { contactEmail } from '../utils/contactEmail'

export default () => {
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const handleError = useHandleError()
  const navigate = useNavigate()
  const sigRef = useRef<ReactSignatureCanvas | null>(null)
  const toast = useToast()
  const [checked, setChecked] = useState(false)
  const [clickedTerms, setClickedTerms] = useState(false)
  const [esign, setEsign] = useState('')

  const signUp = async () => {
    if (password !== confirmPassword) {
      return toast({
        status: 'warning',
        title: 'Passwords do not match',
        description: 'Password fields should match.',
        isClosable: true
      })
    }

    if (!checked) {
      return toast({
        status: 'warning',
        title: 'Terms of Service',
        description:
          'Please check the box to accept our Terms of Service.',
        isClosable: true
      })
    }

    if (!clickedTerms) {
      return toast({
        status: 'warning',
        title: 'Terms of Service',
        description:
          'You did not read the Terms of Service! Please click the Terms of Service link.',
        isClosable: true
      })
    }

    const signature = sigRef.current?.toDataURL()
    if (!esign) {
      if (!signature || sigRef.current?.isEmpty()) {
        return toast({
          status: 'warning',
          title: 'Signature Required',
          description:
            'Please sign to confirm that you agree to the Terms of Service.',
          isClosable: true
        })
      }
    }

    setLoading(true)

    try {
      const {
        user: { uid }
      } = await createUserWithEmailAndPassword(getAuth(), email, password)

      navigate('/')

      if (signature) {
        await uploadString(
          ref(getStorage(), `users/${uid}/signature.png`),
          signature.replace('data:image/png;base64,', ''),
          'base64',
          {
            contentType: 'image/png'
          }
        )
      }
    } catch (e) {
      setLoading(false)
      handleError(e)
    }
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        signUp()
      }}
    >
      <Container>
        <Stack spacing={4}>
          <AuthHeader />
          <Text textAlign='center' fontSize='medium'>
            Have an account?{' '}
            <Link as={RouterLink} to='/sign-in' color='purple.600'>
              Sign In
            </Link>
          </Text>
          <Alert
            borderRadius='md'
            variant='subtle'
            backgroundColor='purple.100'
          >
            <AlertDescription>
              This Registry is for those diagnosed with ALS or an
              ALS-related disorder. Please put in the PALS name and
              signature, not the CALS. Medical professionals or researchers
              may also register to see what info is being asked of their
              ALS patients.
            </AlertDescription>
          </Alert>
          <Stack spacing={2}>
            <Input
              placeholder='Email'
              type='email'
              autoComplete='email'
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <Input
              placeholder='Password'
              type='password'
              autoComplete='new-password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <Input
              placeholder='Confirm Password'
              type='password'
              autoComplete='new-password'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </Stack>
          <Stack spacing={2}>
            <Checkbox
              isChecked={checked}
              onChange={e => setChecked(e.target.checked)}
            >
              I have read and agree to the{' '}
              <Link
                onClick={() => setClickedTerms(true)}
                color='purple.600'
                href='/terms'
                isExternal
              >
                Terms of Service
              </Link>
              .
            </Checkbox>
            <Text>Please sign:</Text>
            <Stack spacing={1}>
              <Stack
                borderWidth={1}
                borderColor='gray.600'
                borderStyle='dashed'
                rounded='md'
              >
                <ReactSignatureCanvas
                  ref={sigRef}
                  penColor='black'
                  canvasProps={{ height: 200 }}
                />
              </Stack>
              <Flex justify='end'>
                <Button
                  size='xs'
                  onClick={() => {
                    sigRef.current?.clear()
                  }}
                  colorScheme='gray'
                  variant='ghost'
                >
                  Clear
                </Button>
              </Flex>
            </Stack>
            <Stack direction='row'>
              <Text fontWeight='bold'>OR</Text>
              <Text>
                I agree that my typed name below serves as my electronic
                signature
              </Text>
            </Stack>
            <Input
              placeholder='E-Signature (Full-name)'
              value={esign}
              onChange={e => setEsign(e.target.value)}
            />
          </Stack>
          <Button mt={3} type='submit' w='full' isLoading={loading}>
            Sign Up
          </Button>
          <Text fontSize='medium'>
            Have an account?{' '}
            <Link as={RouterLink} to='/sign-in' color='purple.600'>
              Sign In
            </Link>
          </Text>
          <Text>
            For questions about the registry, please email us at:{' '}
            {contactEmail}.
          </Text>
        </Stack>
      </Container>
    </form>
  )
}
