import useConfigList from '../hooks/useConfigList'
import Regimens from './Regimens'

export default () => {
  const items = useConfigList('physicalStrategies')

  return (
    <Regimens
      title='Physical Strategies'
      recordLabel='Physical Strategy'
      durationEnabled
      frequencyEnabled
      lists={[
        {
          fieldId: 'physicalStrategyId',
          label: 'Strategy',
          items,
          id: 'physicalStrategies'
        }
      ]}
    />
  )
}
