import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast
} from '@chakra-ui/react'
import {
  CollectionReference,
  QueryDocumentSnapshot,
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAfter,
  where
} from 'firebase/firestore'
import { keys, map } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import zustand from 'zustand'
import UsersTable from '../components/UsersTable'
import useHandleError from '../hooks/useHandleError'
import FirestoreUser, {
  Diagnosis,
  diagnosisTitles,
  roleTitles
} from '../types/FirestoreUser'
import ExportUsers from './ExportUsers'
import UserCounters from './UserCounters'

type Filter =
  | 'firstName'
  | 'lastName'
  | 'patientId'
  | 'email'
  | 'alphaFirst'
  | 'alphaLast'
  | 'alphaEmail'
  | 'patients'
  | 'admins'
  | 'superadmins'
  | Diagnosis
  | 'selectDiagnoses'

const firstNameFilters: Filter[] = ['firstName', 'alphaFirst']

const useSearchStore = zustand<{
  filter?: Filter
  input: string
  docs: QueryDocumentSnapshot<FirestoreUser>[]
  hasMore: boolean
}>(() => ({ input: '', docs: [], hasMore: true }))

const useListStore = zustand<{
  filter?: Filter
  input: string
  docs: QueryDocumentSnapshot<FirestoreUser>[]
  hasMore: boolean
}>(() => ({ input: '', docs: [], hasMore: true }))

const tabs = ['#list', '#search', '#counters', '#export']

export default () => {
  const { hash } = useLocation()
  const navigate = useNavigate()

  return (
    <Stack spacing={5}>
      <Heading>Users</Heading>
      <Tabs
        index={Math.max(tabs.indexOf(hash), 0)}
        onChange={index => navigate(tabs[index])}
      >
        <TabList>
          <Tab>List</Tab>
          <Tab>Search</Tab>
          <Tab>Counters</Tab>
          <Tab>Export</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Search list />
          </TabPanel>
          <TabPanel>
            <Search />
          </TabPanel>
          <TabPanel>
            <UserCounters />
          </TabPanel>
          <TabPanel>
            <ExportUsers />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Stack>
  )
}

const LIMIT = 1000

const Search = ({ list }: { list?: boolean }) => {
  const useStore = useMemo(() => {
    return list ? useListStore : useSearchStore
  }, [list])
  const filter = useStore(state => state.filter)
  const input = useStore(state => state.input)
  const [loading, setLoading] = useState<'more' | 'initial' | false>(false)
  const handleError = useHandleError()
  const docs = useStore(state => state.docs)
  const hasMore = useStore(state => state.hasMore)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const toast = useToast()

  async function search(clear: boolean) {
    setLoading(clear ? 'initial' : 'more')
    const lc = input.toLowerCase().trim()
    try {
      const constraints =
        filter === 'firstName'
          ? [
              where('profile.searchFirstName', '>=', lc),
              where('profile.searchFirstName', '<', lc + '~')
            ]
          : filter === 'lastName'
          ? [
              where('profile.searchLastName', '>=', lc),
              where('profile.searchLastName', '<', lc + '~')
            ]
          : filter === 'email'
          ? [where('email', '>=', lc), where('email', '<', lc + '~')]
          : filter === 'patientId'
          ? [where('patientId', '==', lc.replace('#', ''))]
          : filter === 'alphaFirst'
          ? [orderBy('profile.searchFirstName', 'asc')]
          : filter === 'alphaLast'
          ? [orderBy('profile.searchLastName', 'asc')]
          : filter === 'alphaEmail'
          ? [orderBy('email', 'asc')]
          : filter === 'admins'
          ? [
              where('roles', 'array-contains', 'admin'),
              orderBy('profile.searchLastName', 'asc')
            ]
          : filter === 'patients'
          ? [
              where('roles', 'array-contains', 'patient'),
              orderBy('profile.searchLastName', 'asc')
            ]
          : filter === 'superadmins'
          ? [
              where('roles', 'array-contains', 'superadmin'),
              orderBy('profile.searchLastName', 'asc')
            ]
          : filter && keys(diagnosisTitles).includes(filter)
          ? [
              where('profile.diagnosis', '==', filter),
              orderBy('profile.searchLastName', 'asc')
            ]
          : filter === 'selectDiagnoses'
          ? [
              where('profile.diagnosis', 'in', [
                'als',
                'pls',
                'als-probable'
              ]),
              orderBy('profile.searchLastName', 'asc')
            ]
          : []

      if (!clear && docs.length > 0) {
        constraints.push(startAfter(docs[docs.length - 1]))
      }

      const snap = await getDocs(
        query(
          collection(
            getFirestore(),
            'users'
          ) as CollectionReference<FirestoreUser>,
          limit(LIMIT),
          ...constraints
        )
      )

      useStore.setState({
        docs: clear ? snap.docs : [...docs, ...snap.docs],
        hasMore: snap.size >= LIMIT
      })
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (filter) search(true)
    // eslint-disable-next-line
  }, [useStore, filter, input])

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <SimpleGrid columns={[1, 2]} spacing={3}>
          <FormControl>
            <FormLabel>{list ? 'List By' : 'Search By'}</FormLabel>
            <Select
              placeholder={
                list ? 'Choose List Type' : 'Choose Search Type'
              }
              onChange={e => {
                const filter = e.target.value as Filter
                useStore.setState({ filter, docs: [], input: '' })
                if (inputRef.current) {
                  inputRef.current.focus()
                }
              }}
              value={filter}
            >
              {list ? (
                <>
                  <optgroup label='Alphabetical'>
                    <option value='alphaFirst'>First Name</option>
                    <option value='alphaLast'>Last Name</option>
                    <option value='alphaEmail'>Email</option>
                  </optgroup>
                  <optgroup label='Diagnosis'>
                    <option value='selectDiagnoses'>
                      {diagnosisTitles['als']}/
                      {diagnosisTitles['als-probable']}/
                      {diagnosisTitles['pls']}
                    </option>
                    {map(diagnosisTitles, (text, key) => {
                      return (
                        <option value={key} key={key}>
                          {text}
                        </option>
                      )
                    })}
                  </optgroup>
                  <optgroup label='Role'>
                    <option value='patients'>
                      {roleTitles['patient']}
                    </option>
                    <option value='admins'>{roleTitles['admin']}</option>
                    <option value='superadmins'>
                      {roleTitles['superadmin']}
                    </option>
                  </optgroup>
                </>
              ) : (
                <>
                  <option value='email'>Email</option>
                  <option value='firstName'>First Name</option>
                  <option value='lastName'>Last Name</option>
                  <option value='patientId'>Patient ID</option>
                </>
              )}
            </Select>
          </FormControl>
          {!list && (
            <FormControl>
              <FormLabel>Value</FormLabel>
              <Input
                ref={inputRef}
                value={input}
                isDisabled={!filter}
                onChange={e =>
                  useStore.setState({ input: e.target.value })
                }
                placeholder='Value'
              />
            </FormControl>
          )}
        </SimpleGrid>
      </Stack>
      {docs.length > 0 && (
        <>
          <HStack spacing={2}>
            <Text>Total Displayed: {docs.length}</Text>
            <Button
              onClick={() => {
                navigator.clipboard.writeText(
                  docs.map(doc => doc.data().email).join(',')
                )
                toast({
                  status: 'info',
                  description: `Copied ${docs.length} email address(es) to clipboard`
                })
              }}
            >
              Copy Email Addresses
            </Button>
          </HStack>
          <UsersTable
            docs={docs}
            lastNameFirst={filter && !firstNameFilters.includes(filter)}
          />
        </>
      )}
      {hasMore && docs.length > 0 && (
        <Box>
          <Button
            isLoading={loading === 'more'}
            isDisabled={!!loading}
            size='sm'
            variant='ghost'
            onClick={() => search(false)}
          >
            Load More
          </Button>
        </Box>
      )}
    </Stack>
  )
}
