import useBrands from '../hooks/useBrands'
import useConfigList from '../hooks/useConfigList'
import Regimens from './Regimens'

export default () => {
  const items = useConfigList('supplements')
  const brands = useBrands()

  return (
    <Regimens
      title='Supplements'
      recordLabel='Supplement'
      amountEnabled
      frequencyEnabled
      methodEnabled
      lists={[
        {
          fieldId: 'supplementId',
          label: 'Supplement',
          items,
          id: 'supplements'
        },
        {
          fieldId: 'brandId',
          label: 'Brand',
          items: brands,
          id: 'brands'
        }
      ]}
    />
  )
}
